/* */

.modalContainerQuickWrap .detailsCollectionWrap  {
  margin: 10px 0;
  background-color: #fff;
  border: 1px solid #ffd1a4;
  border-radius: 5px;
  box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
  padding: 10px;
}
.quickProductImg{
  position: relative;
}
.modalContainerQuickWrap  a{
  text-decoration: none;
}
.modalContainerQuickWrap .productImgRemark {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 14px;
}
.modalContainerQuickWrap .productOneClickBtn:hover{
  background-color: transparent;
  color: #e19e07;
  border:1px solid #e0c688;
}
.modalContainerQuickWrap .productOneClickBtn {
  border: 1px solid transparent;
  display: block;
  margin-top: 10px;
  padding: 5px 20px;
  font-size: 16px;
  transition: ease .5s;
  border-radius: 5px;
  background-color: #e19e07;
  color:#333333;
  box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
  text-decoration: none;
  text-align: center;
}
.modalContainerQuickWrap .detailsCollectWraper > h2 {
  font-family: 'AkrobatBold' ,Tahoma,Verdana,sans-serif;
  font-size: 20px;
  padding-top: 10px;
}
.modalContainerQuickWrap .productQty{
 display: none;
}
.modalContainerQuickWrap .productUnitWrap {
  padding: 20px 0 0 0;
}
.modalContainerQuickWrap .detailsCollectionWrap .homeProductItemPrice {
  font-size: 25px;
}
.modalContainerQuickWrap .detailsCollectionWrap{
  margin:0 5px;
}
.modalContainerQuickWrap .detailsCollectionWrap .collectionItemContent{
  max-width: 150px;
}
.modalContainerQuickWrap .cartProdQty{
  margin-top:20px;
  justify-content: center;
}
.modalContainerQuickWrap .detailsCollectionWrap .collectionBtn {
  font-size: 14px;
  padding:5px 10px;
  margin-left: 10px;
  margin-top:10px;
}
.modalContainerQuickWrap .detailsCollectionWrap img {
  width:100px;
  height:auto;
  margin-right: 10px;
}
.quickRightBlock{
  margin-left:10px;
}
.modalContainerQuick {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-start;
  flex-flow: row wrap;
  overflow-y: auto;
}
.modalContainerQuickWrap{
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  z-index: 10000;
}
.modalContainerQuickWrap .cartImageMain {
  max-width: 300px;
  background-color: #fff;
  border: 1px solid #ffd1a4;
  border-radius: 5px;
  box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
}
.modalContainerQuick h2{
  font-size: 16px;
  background-color: rgb(235, 235, 235);
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-family: 'AkrobatRegular', Tahoma, Verdana, sans-serif;
}
/* */

.modalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index: 1001;
  }
  .errorMsg{
    color:red;
  }
  .modalContainer form{
    text-align: center;
  }
  .inputField i {
    position: absolute;
    left: 25px;
    top: 18px;
    color: #002a3d;
    text-align: left;
}
.modalContactTextarea:focus {
  border:1px solid #0ba434;
  -webkit-box-shadow: 1px 1px 18px 5px rgba(43, 255, 0, 0.2);
  -moz-box-shadow: 1px 1px 18px 5px rgba(43, 255, 0, 0.2);
  box-shadow: 1px 1px 18px 5px rgba(43, 255, 0, 0.2);
}
.modalInput:focus {
  border:1px solid #0ba434;
  -webkit-box-shadow: 1px 1px 18px 5px rgba(43, 255, 0, 0.2);
  -moz-box-shadow: 1px 1px 18px 5px rgba(43, 255, 0, 0.2);
  box-shadow: 1px 1px 18px 5px rgba(43, 255, 0, 0.2);
}
.modalInput::placeholder {
  color: #cbcbcb;
}
.inputError:focus {
  border:1px solid #df0000;
  -webkit-box-shadow: 1px 1px 18px 5px rgba(255, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 18px 5px rgba(255, 0, 0, 0.2);
  box-shadow: 1px 1px 18px 5px rgba(255, 0, 0, 0.2);
}
.inputError {
  border: 1px solid #ff0000;
    border-radius: 10px;
    display: block;
    margin-bottom: 10px;
    margin-top: 5px;
    min-width: 300px;
    outline: none;
    font-size: 16px;
    padding: 15px 30px 15px 50px;
    transition: 1s ease;
    font-family: 'CirceRegular', Tahoma, Verdana, sans-serif;
}
.modalInputBtn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #333333;
  text-decoration: none;
}
.modalContactTextarea {
  border: 1px solid #b6b6b6;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 5px;
  min-width: 300px;
  outline: none;
  font-size: 16px;
  padding: 15px;
  transition: 1s ease;
  font-family: 'CirceRegular', Tahoma, Verdana, sans-serif;
}
.modalInputBtn{
  background-image: linear-gradient(to right, #238802 0%, #70d54e 51%, #238802 100%);
    padding: 10px 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: #fff;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: inline-block;
    border: 0;
    font-size: 18px;
    border: 1px solid #238802;
    box-shadow: 1px 0 4px rgb(0 0 0 / 24%);
}
  .modalInput {
    border: 1px solid #b6b6b6;
    border-radius: 10px;
    display: block;
    margin-bottom: 10px;
    margin-top: 5px;
    min-width: 300px;
    outline: none;
    font-size: 16px;
    padding: 15px 30px 15px 50px;
    transition: 1s ease;
    font-family: 'CirceRegular', Tahoma, Verdana, sans-serif;
}
  .inputField{
    position: relative;
    display: block;
  }
  .modalOverlay{
    cursor: pointer;
  }
  .modalContainer {
    width: 350px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    position: relative;
    z-index: 10000;
  }
  .modalContainer h2{
    font-size: 20px;
    background-color: rgb(235, 235, 235);
    padding: 10px 20px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 24px;
    font-family: 'AkrobatRegular', Tahoma, Verdana, sans-serif;
  }
  .modalCloseBtn{
    position: absolute;
    right:-30px;
    top:-30px;
    border-radius: 50% 50%;
    border:1px solid transparent;
    width:30px;
    height:30px;
    transition: ease .5s;
    font-weight: bold;
    color:#ffffff;
    background-color: #333333;
  }
  .modalCloseBtn:hover{
    transform: rotate(180deg);
  }
  .modalOverlay{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 100;
  }
  
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }