/*   Developed in january 2022 | Programming by newweb.kz  */
HTML {
    margin: 0;
    padding: 0;
    height: 100%;
}
BODY {
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 16px;
    color: #333333;
    font-family: 'CirceRegular', Tahoma, Verdana, sans-serif;
    background-color: #fff9f3;
}
.forgotPassword a:hover{
    text-decoration: underline;
}
.forgotPassword a{
    display: block;
    text-decoration: none;
    color:#333;
    margin:10px 0;
    transition: ease .5s;
}
.modalOverlayQuick {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
}
.quick__modal{
    position: absolute;
    top:400px;
    left:0;
    width:1000px;
    height: 400px;
    background-color: #fff;
    z-index: 1000;
    transition: all .3s ease 0s;
    transform: scale(0);
    visibility: hidden;
    opacity: 0;
    border-radius: 30px;
    padding:20px 30px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}
.quick__modal.active{
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    top:49px;
}


.productFront:hover .quickViewProduct{
    transform:  scale(1);
    opacity: 1;
    top:110px;
}
.quickViewProduct:hover{
    background-color: #e6e6e6;
    color:#333333;
    opacity: 0.6;
}
.quickViewProduct i{
    margin-right: 5px;
    color: #238802;
}
.quickViewProduct{
    cursor: pointer;
    display: inline-block;
    text-align: center;
    background-color: #cfcfcf;
    border: 1px solid #cfcfcf;
    color: #333333;
    padding: 8px 10px;
    border-radius: 10px;
    transform: scale(0);
    opacity: 0;
    transition: .5s;
    position: absolute;
    top: -60px;
    left:30px;
    right: 0;
    width: 170px;
    z-index: 2;
    font-size: 14px;
    box-shadow: 0px 1px 8px 0px rgb(34 60 80 / 7%);
}
.quickViewProduct{
    position: absolute;
}
.productFront{
    position: relative;
}
.productImageWrap{
    display: block;
    overflow: hidden;
}
.productFront:hover .productImage{
    transform: scale(.9);
}
.productImage{
    transition: ease .5s;
    width: 100%;
}
.productImgRemarkNew{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: green;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
}
.collectionSumm{
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}
.collectionSumm span{
    font-size:12px !important;
    text-transform: uppercase;
}
.collectionBtn:hover{
    background-color: transparent;
    color:#238802;
}
.collectionBtn{
    border: 1px solid #238802;
    background-color: #238802;
    color: #fff;
    border-radius: 10px;
    padding:10px 20px;
    margin-top: 20px;
    transition: ease .5s;
}
.detailsCollectionWrap span{
    font-size:35px;
}
.collectionItem{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
}
.detailsCollectionWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}
.collectionItemContent h3{
    max-width: 200px;
    font-size: 14px;
}
.detailsCollectWraper > h2 {
    font-family: 'AkrobatBold' ,Tahoma,Verdana,sans-serif;
    font-size: 30px;
    padding-bottom: 10px;
}
.detailsCollectionWrap{
    margin:30px 0;
    background-color: #fff;
    border: 1px solid #ffd1a4;
    border-radius: 5px;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
    padding: 30px;
}
.editor-wrapper {
    border: 1px solid #c4cdd5;
  }
  .message-toolbar {
    border-bottom: 1px solid #c4cdd5;
    margin-bottom: 0px;
    padding: 6px 5px;
  }
  .message-editor {
    height: 150px;
    font-size: 16px;
    padding: 0px 10px;
  }
  .rdw-option-wrapper {
    min-width: 35px;
    height: 30px;
  }
  .html-output {
    border: 1px solid silver;
    padding: 20px;
    background-color: #fafafa;
  }
.productDetailsDescr{
    padding-top:30px;
}
.productInstock{
    background-color: #238802;
    color:#fff;
    padding:5px 10px;
    border-radius: 10px;
    position: absolute;
    left:30px;
    top:10px;
    z-index: 30;
}
.productOutInstock{
    background-color: red;
    color:#fff;
    padding:5px 10px;
    border-radius: 10px;
    position: absolute;
    left:30px;
    top:10px;
    z-index: 30;
}
.homeProductItem .productInstock{
    background-color: #238802;
    color:#fff;
    padding:5px 10px;
    border-radius: 10px;
    position: absolute;
    left:10px;
    top:10px;
    z-index: 30;
    font-size: 12px;
}
.homeProductItem .productOutInstock{
    background-color: red;
    color:#fff;
    padding:5px 10px;
    border-radius: 10px;
    position: absolute;
    left:10px;
    top:10px;
    z-index: 30;
    font-size: 12px;
}
#mobileHeaderPhone a{
    display:none;
}
.mobileFooter{
    display: none;
}
.mobileShow {
    display: none;
}
.checkoutCart table tr:hover {
    background-color: #b0b0b0;
}

.checkoutCart table tr td, .checkoutCart table tr th {
    padding: 5px 10px;
}
.checkoutCart table tr {
    border: 1px solid #fff;
    transition: 1s ease;
}
.checkoutCart table {
    background-color: #f4f4f4;
    color: #333;
    font-size: 16px;
}
.checkoutContent {
    align-items: center;
    display: flex;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 30px;
}
.checkoutItemBtn button i{
    margin-right: 10px;
}
.checkoutItemBtn button:hover {
    background-position: 100%;
    color: #333;
    text-decoration: none;
}
.checkoutItemBtn button{
    background-image: linear-gradient(90deg,#238802 0,#70d54e 51%,#238802);
    background-size: 200% auto;
    border: 1px solid #238802;
    border-radius: 10px;
    box-shadow: 0 0 20px #eee;
    box-shadow: 1px 0 4px rgb(0 0 0 / 24%);
    color: #fff;
    display: block;
    font-family: NeoSansProMedium,Tahoma,Verdana,sans-serif;
    font-size: 18px;
    margin-top: 40px;
    padding: 10px 30px;
    text-align: center;
    transition: .5s;
}
.inputAuth:focus {
    border: 1px solid #ffbd09;
    -webkit-box-shadow: 1px 1px 18px 5px rgba(217, 134, .2);
    -moz-box-shadow: 1px 1px 18px 5px rgba(217, 134, 9, .2);
    box-shadow: 1px 1px 18px 5px rgba(217, 134, 9, .2);
}
.bread{
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom:1px solid #dcdcdc;
}
.checkOutForm{
    padding-top:30px;
}
.inputAuth {
    border: 1px solid #b6b6b6;
    border-radius: 10px;
    display: block;
    font-family: NeoSansProRegular,Tahoma,Verdana,sans-serif;
    margin-bottom: 10px;
    margin-top: 5px;
    min-width: 300px;
    outline: none;
    padding: 10px 20px;
    transition: 1s ease;
}
.modalOverlayMy{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 99;
}
.header__burger.active span{
    transform: scale(0);
}
.header__burger.active:before{
    transform: rotate(45deg);
    top:8px;
}
.homeCategoryItem:hover .homeCategoryImg{
    transform: scale(.9);
}
.homeCategoryImg{
    transition: ease .5s;
    width: 100%;
}
.header__burger.active::after{
    transform: rotate(-45deg);
    bottom: 8px;
}
.header__menu{
    position: absolute;
    top:400px;
    left:0;
    width:1000px;
    height: 400px;
    background-color: #fff;
    z-index: 1000;
    transition: all .3s ease 0s;
    transform: scale(0);
    visibility: hidden;
    opacity: 0;
    border-radius: 30px;
    padding:20px 30px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}
.header__menu.active{
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    top:49px;
}
.categorySearch {
    height: 50px;
    overflow: hidden;
    position: relative;
    width: 300px;
}
.header__burger span{
    position: absolute;
    background-color: #fff;
    left:0;
    width: 100%;
    height: 2px;
    top:9px;
    transition: all .3s ease 0s;
}
.header__burger{
    position: relative;
    width:20px;
    height:18px;
    margin-right: 10px;
}
.header__burger:before,
.header__burger:after{
    content: '';
    background-color: #fff;
    position: absolute;
    width:100%;
    height:2px;
    transition: all .3s ease 0s;
}
.header__burger:before{
    top:2px;
}
.header__burger:after{
    bottom: 0;
}
main{    
    display: block;
    overflow: hidden;
}
.dashBoardTopBtns a{
    margin-right: 20px;
}
.bashboardTopMenu{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}
.productQty > div{
    padding:5px 10px;
}
.productQty{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding-top:10px;
}
.cartUnitType button{
    border:0;
}
.cartProdQuantity{
    justify-content: center;
}
.favDelete{
    color:#e19e07;
}
.productOneClickBtn i{
    margin-right: 10px;
}
.productOneClickBtn{
    border:1px solid transparent;
    padding:5px 20px;
    font-size: 18px;
    transition: ease .5s;
    border-radius: 5px;
    background-color: #e19e07;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
}
.productOneClickBtn:hover{
    background-color: transparent;
    border: 1px solid #e19e07;
}
.productFavBtnI{
    transition: ease .5s;
}
.productFavBtn i{
    margin-right: 10px;
}
.productOneClickBtn:hover .productFavBtnI{
    transform: rotate(90deg);
}
.productFavBtn{
    border:1px solid transparent;
    margin-top:50px;
    padding:5px 20px;
    font-size: 18px;
    background-color: transparent;
    border-radius: 5px;
    transition: ease .5s;
}
.productFavBtn:hover{
    background-color: transparent;
    color:#e19e07;
}
.productCartBtn i{
    margin-right: 10px;
}
.productCartBtn:hover {
    background-position: 100%;
    color: #333;
    text-decoration: none;
}
.productDetailsLeft{
    padding: 30px;
    border-right: 1px solid #e1e1e1;
    background-color: #f7f7f7;
}
.productCartBtn{
    border:1px solid transparent;
    background-image: linear-gradient(90deg,#238802 0,#70d54e 51%,#238802);
    background-size: 200% auto;
    border: 1px solid #238802;
    border-radius: 5px;
    box-shadow: 0 0 20px #eee;
    box-shadow: 1px 0 4px rgb(0 0 0 / 24%);
    color: #fff;
    display: block;
    font-size: 23px;
    padding: 10px 30px;
    text-align: center;
    transition: .5s;
    cursor: pointer;
    margin-right: 30px;
}
.productUnitWrap .active{
    background-color:#686868;
    border:0;
    border-radius: 5px;
    color:#fff;
}
.cartUnitType .active{
    background-color:#686868;
    border:0;
    border-radius: 5px;
    color:#fff;
}
.cartUnitTypeUp{
    font-size:10px;
}
.cartUnitTypeKor{
    font-size:10px;
}
.cartUnitType{
    margin-top:5px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    font-size:12px;
}
.favMoveToCart:hover {
    background-position: right center; /* change the direction of the change here */
    color: #333333;
    text-decoration: none;
  }
.favMoveToCart{
    background-image: linear-gradient(90deg,#238802 0,#70d54e 51%,#238802);
    background-size: 200% auto;
    border: 1px solid #238802;
    border-radius: 5px;
    box-shadow: 0 0 20px #eee;
    box-shadow: 1px 0 4px rgb(0 0 0 / 24%);
    color: #fff;
    display: block;
    font-size: 14px;
    padding: 5px 10px;
    text-align: center;
    transition: .5s;
    cursor: pointer;
}
.favRemoveBtn:hover{
    background-color: transparent;
    color:rgb(151, 0, 0);
}
.favRemoveBtn{
    background-color: rgb(151, 0, 0);
    color:#fff;
    border:0;
    border-radius: 5px;
    padding:5px 10px;
    transition: ease .5s;
}
.favProductBtn{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding:10px;
}
.wishlistContent{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    padding:50px 0;
}
.cartProdTotalPrice {
    font-family: "AkrobatLight",Tahoma,Verdana,sans-serif;
    font-size: 25px;
}
.cartSummaryBtns>button:hover {
    background-color: #27242b;
    color: #fff;
}
.cartSummaryBtns {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}
.cartSummaryBtns>button {
    background-color: #fff;
    border: 0;
    border-radius: 5px;
    color: #27242b;
    font-size: 10px;
    padding: 5px 10px;
    text-transform: uppercase;
    transition: .5s ease;
}
.cartSummaryBtns a{
    text-decoration: none;
}
.cartSummaryBtns a>button:hover {
    background-position: 100%;
    color: #333;
    text-decoration: none;
}
.cartSummaryBtns a>button {
    background-image: linear-gradient(90deg,#238802 0,#70d54e 51%,#238802);
    background-size: 200% auto;
    border: 1px solid #238802;
    border-radius: 10px;
    box-shadow: 0 0 20px #eee;
    box-shadow: 1px 0 4px rgb(0 0 0 / 24%);
    color: #fff;
    display: block;
    font-family: "AkrobatLight",Tahoma,Verdana,sans-serif;
    font-size: 22px;
    margin-top: 40px;
    padding: 10px 30px;
    text-align: center;
    transition: .5s;
}
.wishlistWrap h2 {
    color: #adadad;
    line-height: 1;
    font-family: 'AkrobatLight',Tahoma, Verdana, sans-serif;
    font-size: 30px;
}
.wishlistWrap {
    text-align: center;
    padding: 50px 0;
}
.cartSummaryWrap {
    text-align: right;
}
.cartProdPrice{
    font-size: 18px;
    font-family: "AkrobatLight",Tahoma,Verdana,sans-serif;
}
.wishlistWrap a:hover {
    background-position: 100%;
    color: #333;
    text-decoration: none;
}
.wishlistWrap a {
    background-image: linear-gradient(90deg,#238802 0,#70d54e 51%,#238802);
    background-size: 200% auto;
    border: 1px solid #238802;
    border-radius: 10px;
    box-shadow: 0 0 20px #eee;
    box-shadow: 1px 0 4px rgb(0 0 0 / 24%);
    color: #fff;
    display: block;
    display: inline-block;
    font-size: 18px;
    margin-top: 40px;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    transition: .5s;
}
.cartSummaryTotal{
    width: 100%;
    text-align: right;
    font-size: 30px;
    font-family: "AkrobatBold",Tahoma,Verdana,sans-serif;
}
.cart-empty a:hover {
    background-position: 100%;
    color: #333;
    text-decoration: none;
}
.wishlistWrap i {
    color: rgb(254,144,41);
    display: block;
    font-size: 320px;
    padding: 40px 0;
}
.cart-empty a {
    background-image: linear-gradient(90deg,#238802 0,#70d54e 51%,#238802);
    background-size: 200% auto;
    border: 1px solid #238802;
    border-radius: 10px;
    box-shadow: 0 0 20px #eee;
    box-shadow: 1px 0 4px rgb(0 0 0 / 24%);
    color: #fff;
    display: block;
    display: inline-block;
    font-size: 18px;
    margin-top: 40px;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    transition: .5s;
}
.list-group-item .row{
    align-items: center;
    transition: ease .5s;
}
.cart-empty i {
    color: rgb(254,144,41);
    display: block;
    font-size: 320px;
    padding: 40px 0;
}
.cart-empty p {
    font-family: 'AkrobatLight',Tahoma, Verdana, sans-serif;
    font-size: 30px;
    color: #adadad;
}
.categorySearch input {
    border: none;
    height: 100%;
    outline: none;
    padding-left: 20px;
    padding-top: 20px;
    width: 100%;
}
.catSeachLabel {
    border-bottom: 1px solid #000;
    bottom: -1px;
    pointer-events: none;
}
.catSeachLabel, .catSeachLabel:after {
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
}
.categorySearch input:focus+.catSeachLabel .catSeachName, .categorySearch input:valid+.catSeachLabel .catSeachName {
    color: #333;
    font-size: 12px;
    -webkit-transform: translateY(-120%);
    transform: translateY(-120%);
}
.catSeachLabel:after {
    border-bottom: 3px solid #3d3d3d;
    bottom: 0;
    content: "";
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: all .3s ease;
}
.catSortSelect {
    background: #d09239;
    background: linear-gradient(90deg,#f06b3f,#ffcb20);
    border-radius: 5px;
    color: #333333;
    cursor: pointer;
    padding: 10px 30px;
    font-family: 'CirceBold';
}
.catSortSelect option {
    color: #333;
    cursor: pointer;
    padding: 5px 0;
}
.categorySearch input:focus + .catSeachLabel .catSeachName, 
.categorySearch input:valid + .catSeachLabel .catSeachName
{
    transform: translateY(-120%);
    font-size: 12px;
    color:#333333;
    padding-left:20px;
}
.categorySearch input:focus + .catSeachLabel::after
{
    transform: translateX(0%);
}
.cart-empty {
    text-align: center;
}
.cartContentWrap {
    margin: 30px 0;
}
.cartCountInp{
    border:0;
    outline: none;
    max-width: 80px;
    text-align: center;
    background-color: transparent;
}
.cartCountInp::-webkit-outer-spin-button,
.cartCountInp::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.cartMinus:hover,
.cartPlus:hover {
    color: #fff;
    background-color: #39c30c;
}
.cartCountInp {
    font-size: 20px;
    margin: 0 10px;
}
.cartMinus, .cartPlus {
    background-color: transparent;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    box-shadow: none;
    display: block;
    height: 26px;
    outline: none;
    position: relative;
    text-align: center;
    transition: 1s ease;
    width: 26px;
}
.cartProdQty {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.list-group-item{
    text-align: center;
}
.cartProdPriceSale, .headerTopSalePrice {
    font-size: 14px!important;
    text-decoration: line-through;
    color:red;
}
.list-group-item a h3:hover{
    color:rgb(254,144,41);
}
.list-group-item a h3 {
    color: #333;
    font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
    font-size: 18px;
    text-align: left;
    text-decoration: none;
    transition: ease .5s;
}
.list-group-item img {
    max-height: 100px;
    width: auto;
}
.list-group-item a {
    color: #333;
    text-decoration: none;
    transition: .3s ease;
}
.productUnitKor{
    padding:5px 20px;
    background-color: rgb(234, 234, 234);
    cursor: pointer;
    border:0;
    border-radius: 5px;
}
.productUnitUp{
    padding:5px 20px;
    margin-right: 20px;
    background-color: rgb(234, 234, 234);
    cursor: pointer;
    border:0;
    border-radius: 5px;
}
.productUnitWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding:20px 0;
}
.headerSearchItem {
    align-items: center;
    display: flex;
    text-decoration: none;
}
.headerSearchItem img {
    margin-right: 10px;
}
.headerSearchResult p {
    color: #000!important;
    font-family: GothamProBold,Tahoma,Verdana,sans-serif!important;
    font-size: 10px!important;
}
.headerSearchResult {
    background-color: #fff;
    border: 1px solid #ddd;
    height: 200px;
    overflow: hidden;
    overflow-y: auto;
    padding-left: 20px;
    position: absolute;
    width: 480px;
    top:40px;
    left:20px;
    z-index: 200;
}
.headerForm {
    position: relative;
}
.dropdown .headerMenuItems a {
    border-bottom: 1px solid #e95867;
    color: #fff;
    display: block;
    font-family: GothamProRegular,Tahoma,Verdana,sans-serif;
    padding: 7px 10px;
    text-decoration: none;
    transition: 1s ease;
}
.headerMenuItems {
    list-style: none;
}
.dropdown .headerMenuItems:first-child {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
}
.dropdown .headerMenuItems {
    -webkit-animation: enter .2s ease forwards;
    animation: enter .2s ease forwards;
    background: #f67280;
    opacity: 0;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
}
.dropdown {
    display: none;
    left: 0;
    position: absolute;
    top: 137px;
    width: 100%;
    z-index: 3;
}
.saleContentWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-top:30px;
}
.searchContentWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-top:30px;
}
.commonPagesContentWrap > h1{
    font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
}
.productCartBtns{
    margin-top:20px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    border:1px solid #f0f0f0;
    border-radius: 5px;
    padding:20px;
    background-color: #fcf5ec;
}
.productDetailsBrand{
    color:#333333;
}
.productDetailsSPrice{
    font-size: 30px;
}
.productDetailsSPrice span{
    font-size: 20px;
    color: #adadad;
}
.productDetailsRight{
    min-width: 400px;
    padding:30px;
    text-align: left;
}
.productDetailsRight .productQty{
    justify-content: flex-start;
}
.productDetailsRight .cartProdQty{
    justify-content: flex-start;
}
.productDetailsContentWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.productDetailsSPriceSale{
    text-decoration: line-through;
}
.relatedWrap > h2{
    font-family: 'AkrobatBold' ,Tahoma,Verdana,sans-serif;
    font-size: 30px;
    padding-bottom: 10px;
}
.productDetailsContent h1{
    font-family: 'AkrobatBold' ,Tahoma,Verdana,sans-serif;
    font-size: 30px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #ebebeb;
}
.productDetailsContent{
    width: 800px;
    background-color: #fff;
    border: 1px solid #ffd1a4;
    border-radius: 5px;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
    padding:30px;
    position: relative;
}
.relatedWrap{
    margin-top: 50px;
}
.productDetails{
    padding-top:40px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.cartImageMain{
    max-width: 400px;
    background-color: #fff;
    border: 1px solid #ffd1a4;
    border-radius: 5px;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
}
.related{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
}
.brandLabel::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #dcdcdc;
    vertical-align: middle;
    margin-left: -10px;
    margin-right: 10px;
    transition: 0.2s;
    border: 3px solid #dcdcdc;
}
.categoryBrandContent input[type="checkbox"] {
    display: none;
}
.brandLabel{
    cursor: pointer;
    margin:5px 10px;
    transition: ease .5s;
}

.categoryBrandContent input[type="checkbox"]:checked + label::before{
    background-color:#7a0608;
}
.brandLabel:hover {
    opacity:0.8;
    color:#fa4248;
}
.read-more-button {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #777;
    cursor: pointer;
    display: inline-block;
    display: block;
    margin-top: -13px;
    padding: 5px;
    position: relative;
    text-align: center;
    transition: .5s ease;
    z-index: 2;
}
.formMainAuth p{
    font-size: 12px;
}
.oneCategoryWrap h1 span {
    background-color: #5f5d61;
    border-radius: 5px;
    color: #fff;
    font-family: 'CirceRegular', Tahoma, Verdana, sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-left: 20px;
    margin-top: 5px;
    padding: 5px;
}
.bread ul li a {
    color: #505050;
    text-decoration: none;
}
.categoryBrandsWrap h3 {
    background: #d09239;
    background: linear-gradient(90deg,#f06b3f,#ffcb20);
    border-radius: 5px 5px 0 0;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    text-align: center;
    font-family: 'CirceBold';
    text-transform: uppercase;
}
.bread ul li {
    margin: 0 10px;
    list-style: none;
}
.formMainAuth{
    background-color: #fff;
    border: 1px solid #ffd1a4;
    border-radius: 5px;
    margin: 30px 0;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
    padding: 30px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
}
.bread ul {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
}
.read-more-button:hover{
    color:#7a0608;
    border: 1px solid #7a0608;
}
.categoryBrandContent {
    border:1px solid rgb(222, 222, 222);
    background-color: #fff;
    min-width:230px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding-bottom:40px;
    padding: 10px;
    transition:ease 0.3s;
}
.categoryBrandContent .bottom{
    position: absolute;
    bottom:0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255,255,255,1) 50%);
    width: 100%;
    height:40px;
    opacity: 1;
    transition:ease 0.3s;
}
.oneCategoryWrap h1 {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    font-family: 'AkrobatBold' ,Tahoma,Verdana,sans-serif;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 40px;
    align-items: center;
}
.categoryBtnMore button:hover{
    background-color: transparent;
    color: #238802;
}
.categoryBtnMore{
    text-align: center;
    width:100%;
}
.categoryBtnMore button {
    background-color: #238802;
    border: 1px solid #238802;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    margin: 10px 0;
    padding: 5px 10px;
    transition: .5s ease;
}
.productImage{
    min-width: 230px;
    min-height: 230px;
}
.oneCategoryWrap{
    min-height: 500px;
}
.categoryBrandsWrap{
    width: 18%;
    margin-right: 20px;
    display: block;
}
.categoriesContentWrap {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 50px 0;
}
.categoryItemsBtnWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 78%;    
}
.modalContainerQuickWrap .productDetailsContent  {
    width: 400px !important;
    background-color: #fff;
    border: 1px solid #ffd1a4;
    border-radius: 5px;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
    padding: 10px;
    position: relative;
  }
.modalContainerQuickWrap .productDetailsContent h1 {
    font-family: 'AkrobatBold' ,Tahoma,Verdana,sans-serif;
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top:0;
    border-bottom: 1px solid #ebebeb;
}
.modalContainerQuickWrap .modalContainerQuick .productInstock {
    background-color: #238802;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    left: 15px;
    font-size: 12px;
    top: 15px;
    z-index: 30;
}
.modalContainerQuickWrap .productDetailsContent .productFavBtn {
    border: 1px solid transparent;
    margin-top: 20px;
    padding: 5px 20px;
    font-size: 14px;
    background-color: transparent;
    border-radius: 5px;
    transition: ease .5s;
}
.modalContainerQuickWrap .productDetailsContent .productCartBtns {
    margin-top: 0;
    display: flex;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 10px;
    background-color: #fcf5ec;
}
.modalContainerQuickWrap .productOutInstock {
    background-color: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    left: 20px;
    top:20px;
    z-index: 30;
    font-size: 12px;
}
.modalContainerQuickWrap .productDetailsContent .productCartBtn {
    border: 1px solid transparent;
    background-image: linear-gradient(90deg,#238802 0,#70d54e 51%,#238802);
    background-size: 200% auto;
    border: 1px solid #238802;
    border-radius: 5px;
    box-shadow: 0 0 20px #eee;
    box-shadow: 1px 0 4px rgb(0 0 0 / 24%);
    color: #fff;
    display: block;
    font-size: 18px;
    padding: 5px 15px;
    text-align: center;
    transition: .5s;
    cursor: pointer;
    margin-right: 0;
}
.modalContainerQuickWrap .productDetailsContent .productDetailsRight {
    min-width: 200px;
    padding: 10px;
    text-align: left;
}
.modalContainerQuickWrap .productDetailsContent .productDetailsLeft {
    padding: 10px;
    width:100%;
    font-size: 14px;
}
.modalContainerQuickWrap .productDetailsContent .productUnitWrap button{
    font-size: 12px;
}
.modalContainerQuickWrap .productDetailsContent  .productDetailsSPrice {
    font-size: 20px;
}
.categoryNavigatinoWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start; 
}
.categoryItemsWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-left:-10px;
}
.commonPagesContentWrap{
    min-height: 500px;
}
.red{
    color:red;
}
.homeProductItemPrice{
    font-size: 30px;
}
.homeProductItemPrice span{
    font-size: 20px;
    margin-left:10px;
}
.homeProductItemPriceSale{
    text-decoration: line-through;
}
.catProductBtn{
    text-align: center;
}
.favBtnProduct:hover{
    background-color:transparent;
    color:orange;
}
.favBtnProduct{
    border:0;
    background-color: orange;
    margin:10px;
    color:#fff;
    font-size: 20px;
    border-radius: 5px;
    transition: ease .5s;
}
.cartBtnProduct:hover{
    background-color: transparent;
    color:#238802;
}
.cartBtnProduct i{
    font-size: 20px;
}
.cartBtnProduct{
    border:0;
    background-color: green;
    margin:10px;
    color:#fff;
    font-size: 16px;
    border-radius: 5px;
    transition: ease .5s;
    padding:5px 10px;
}
.productImgRemark span{
    background-color: orange;
    padding:5px 10px;
    color:#fff;
    border-radius: 5px;
}
.productImgRemark p{
    background-color: red;
    padding:5px 10px;
    color:#fff;
    border-radius: 5px;
}
.homeProductItemInfo{
    text-align: center;
}
.homeProductItemBrand{
    font-size: 12px;
    color:#818181;
}
.homeProductItemCode{
    font-size: 12px;
    color:#818181;
}
.homeTabsCat::before{
    content:'';
    position: absolute;
    width:100%;
    height:1px;
    background-color: #ffe0b5;
    top:4px;
}
.homeTabsCat{
    position: relative;
    padding-top: 40px;
    min-height: 400px;
    border-top:1px solid #ffe0b5;
}
.homeTabs .Mui-selected {
    background-color: #fff;
    color: #d09239;
    border-bottom: 3px solid #d09239;
}
.homeTabsBtn{
    font-size: 24px;
    color:#333333;
    background-color: transparent;
    border:0;
    outline: none;
    padding:15px 40px;
    font-family: 'AkrobatRegular', Tahoma, Verdana, sans-serif;
}
.productImgRemark{
    position: absolute;
    top:10px;
    right:10px;
}
.productImage{
    border-radius: 10px 10px 0 0;
    height:230px;
}
.homeProductItemImg{
    position: relative;
    z-index: 1;
}
.productFront a{
    color:#333333;
    text-decoration: none;
    display: block;
}
.productFront h2 { 
    font-size: 16px;
    margin:10px 0;
    background-color: #cdcdcd;
    padding:5px 10px;
    min-height: 70px;
}
.homeProductItem:hover{
    border:3px solid #ffaf3f;
}
.commonPagesContent{
    background-color: #fff;
    border: 1px solid #ffd1a4;
    border-radius: 5px;
    margin: 30px 0;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
    padding: 30px;
}
.homeCategoryItem:hover{
    color:#e19e07;
}
.homeCategoryItem p{
    border-top:1px solid #ffd1a4;
    text-align: center;
    font-family: 'CirceBold', Tahoma, Verdana, sans-serif;
    line-height: 1;
    padding:10px 0;
    font-size: 14px;
}
.homeCategoryItem{
    border:1px solid #ffd1a4;
    width:180px !important;
    background-color: #fff;
    text-decoration: none;
    color:#333333;
    transition: ease .5s;
}
.homeSlickCategories .slick-dots{
    bottom:-50px;
}
.footerLink i{
    color:rgb(254,144,41);
    margin-right: 10px;
    font-size: 16px;
}
.footerLink:hover{
    color:rgb(254,144,41);
}
.footerLink{
    text-decoration: none;
    color: #fff;
    display: block;
    transition: ease .5s;
}
.footerPhone span{
    font-size: 14px;
}
.footerPhone{
    font-size:22px;
    margin-top: 10px;
}
.footerCopyright a:hover{
    text-decoration: underline;
}
.footerCopyright a{
    color:rgb(254,144,41);
    text-decoration: none;
}
.footerCopyright{
    text-align: center;
    color:#787877;
    font-size: 11px;
}
.footerCategory ul{
    margin-top:10px;
}
.footerCategory ul li a::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    border: 4px solid transparent;
    border-left: 4px solid #e19e07;
}
.footerCategory ul li a:hover{
    color:#fff;
}
.footerCategory ul li a{
    text-decoration: none;
    color:#818181;
    position: relative;
    padding-left: 10px;
    transition: ease .5s;
    font-size: 14px;
}
.footerCategory ul li{
    list-style: none;
}
.footerCategory h4{
    font-size: 20px;
    font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
    color:rgb(254,144,41);
    text-transform: uppercase;
}
.homeProductItem img{
    width:230px;
    height:230px;
}
.footerCategory{
    margin:50px 0 50px 40px;
}
.footerSocialWrap a:hover{
    color:#333333;
}
.footerSocialWrap a{
    display: block;
    color:#fff;
    margin:40px;
    font-size: 20px;
    transition: ease .5s;
}
.footerSocialWrap{
    background-color: rgb(254,144,41);    
    height: 100%;
    padding-top:85px;
    padding-bottom:65px;
}
.footerContent{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;    
    width:100%;
}
.footerBottomContentWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
}

.homeProductItem{
    background-color: #fff;
    padding:5px;
    border:3px solid #fbebd5;
    border-radius: 10px;
    width: 245px !important;
    margin:0 5px 10px 5px;
    transition: ease .5s;
    position: relative;
    z-index: 1;
}
.footerLogoWrap p{
    font-family: 'CirceExtraBold', Tahoma, Verdana, sans-serif;
    font-size: 26px;
}
.footerLogoWrap h4{
    font-size: 12px;
    font-family: 'CirceBold', Tahoma, Verdana, sans-serif;
    text-transform: uppercase;
}
.footerLogoWrap{
    text-align: center;
    padding:25px 45px 0 45px;
    color:#e4a354;
}
.footer::before{
    width:100%;
    height:1px;
    background-color: #494949;
    content: '';
    top:4px;
    position: absolute;
    z-index: 1;
}
.footer{
    border-top:1px solid #494949;
    position: relative;
    min-height: 400px;
    z-index: 0;
}
.footerWrap{
    background-color: #303030;
    padding-top:15px;
}
.footerSubscribeWrap img{
    margin-top:6px;
    margin-right: 20px;
}
.footerSubscribeWrap p{
    color:#fff;
    font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
    font-size: 20px;
    line-height: 1;
    padding-top:5px;
    text-transform: uppercase;
}
.footerSubscribeWrap::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 10000px;
    background: rgb(254,144,41);
}
.footerSubscribe button:hover{
    color:#e19e07;
    padding:12px 30px 12px 30px;
}
.footerSubscribe button{
    position: absolute;
    right:6px;
    top:6px;
    border-radius: 50px;
    background-color: #333333;
    border:0;
    color:#fff;
    font-size: 12px;
    font-family: 'CirceBold', Tahoma, Verdana, sans-serif;
    padding:12px 25px 12px 25px;
    transition: ease .5s;
}
.footerSubscribe input{
    border-radius: 50px;
    border:1px solid transparent;
    outline: none;
    margin:4px;
    padding:22px 100px 22px 25px;
    position: absolute;
    left:0;
    right:0;
    bottom:4px;
    top:0;
    z-index: 0;
}
.footerSubscribe{
    position: relative;
    display: block;
    min-width: 600px;
}
.footerSubscribeWrap{
    background: linear-gradient(90deg, rgb(243,187,108) 0, rgb(254,144,41) 100%), rgb(243,187,108);
    position: relative;
    margin-left: 250px;
    border-radius: 50px 0 0 50px;
    display: flex;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 8px;
    margin-bottom: -30px;
    z-index: 2;
}
footer{
    margin-top: 100px;
    display: block;
    overflow: hidden;
}
.homeAboutBtn:hover{
    transform: translateX(20px);
}
.homeAboutBtn{
    margin-top: 30px;
    transition: ease .5s;
}
.homeAboutCompanyText{
    width:70%;
}
.homeAboutCompany{
    background-color: #fff;
    border: 1px solid #ffd1a4;
    border-radius: 5px;
    margin: 30px 0;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
    padding:30px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.homeAboutTitle{
    background: url(/public/uploads/design/homeAboutLines.png) center center no-repeat;
}
.homeBanners a img:hover{
    transform: scale(.95);
}
.homeBanners a img{
    transition: ease .5s;
}
.homeBestItem:nth-child(4){
    border-right: 0;
}
.homeBestItem h3{
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
}
.homeBestItem img{
    margin-right: 10px;
}
.homeBestItem{
    margin:15px 0;
    padding:0 40px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
    border-right: 1px solid #ffd1a4;
}
.homeBestsWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-around;
    background-color: #fff;
    border: 1px solid #ffd1a4;
    border-radius: 5px;
    margin: 60px 0 30px 0;
    box-shadow: 0 0 18px -1px rgb(34 60 80 / 10%);
}
.homeSlickCategories{
    border:1px solid #ffd1a4;
    padding:15px;
}
.homeSlickCategoriesWrap{
    border:1px solid #ffd1a4;
    margin: 30px 0 50px 0;
    padding:5px;
}
.homeTitle img{    
    margin-right: 10px;    
}
.homeTitle{
    font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
}
.homeSliderBanners{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.homeBanners a:nth-child(1){
    margin-bottom: 38px;
}
.homeBanners a{
    display: block;
}
.slick-prev:before, .slick-next:before {
    color:#e19e07 !important;
}
.slick-dots li button:before {
    font-size: 10px !important;
    color:#e19e07 !important;
}
.homeSlider{
    margin-bottom: 30px;
    width:830px;
    margin-right: 3px;
}
.headerNavigation > ul li a::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 0;
    border: 4px solid transparent;
    border-left: 4px solid #fff;
}
.headerNavigation > nav ul li a:hover{
    color:#fff;
}
.headerNavigation > nav ul li a{
    color:#333333;
    padding:10px;
    text-decoration: none;
    display:inline-block;
    font-size: 16px;
    margin-top: 3px;
    margin-left:20px;
    transition: ease .5s;
    position: relative;
}
.headerNavigation > nav ul li{
    list-style: none;
}
.headerCatalogBtn:hover{
    color:#333333;
    transform: translateX(10px);
}
.headerCatalogBtn{
    user-select: none;
    background: url(/public/uploads/design/catBtn.png) top right no-repeat;
    font-size: 20px;
    color:#fff;
    font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
    padding:5px 25px 10px 28px;
    text-transform: uppercase;
    margin:3px;
    cursor: pointer;
    transition: ease .5s;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
}
.headerNavigation > nav ul{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
}
header{
    margin-bottom:30px;
}
.headerNavigation::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 10000px;
    background: rgb(254,144,41);
}
.headerNavigation{
    background: linear-gradient(90deg, rgb(243,187,108) 0, rgb(254,144,41) 100%), rgb(243,187,108);
    position: relative;
    margin-left: 250px;
    border-radius: 50px 0 0 50px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    z-index: 100;
}
.headerBtn:hover{
    color:#333333;
}
.headerBtn{
    margin-top: 25px;
    background: url(/public/uploads/design/headerBtn.jpg) top right no-repeat;
    text-transform: uppercase;
    font-family: 'CirceBold', Tahoma, Verdana, sans-serif;
    padding:10px 20px 10px 60px;
    color:#fff;
    transition: ease 1s;
    cursor: pointer;
    font-size:14px;
}
.headerCart p{
    background-color: #fff;
    border-radius: 50px;
    display: inline-block;
    padding:5px 10px;
    font-size: 18px;
    position: absolute;
    color:#333333;
    right:-5px;
    font-family: 'CirceExtraBold', Tahoma, Verdana, sans-serif;
    bottom:-10px;
    line-height: 1;
}
.headerCartBtn{
    margin-right: 20px;
}
.headerCartBtn a i{
    color:#e19e07;
    font-size: 43px;
}
.headerFavBtn a i{
    color:#e19e07;
    font-size: 43px;
}
.headerFavBtn a{
    text-decoration: none;
}
.headerCartBtn a{
    text-decoration: none;
}
.headerCart{
    position: relative;
}
.headerCartFavBtns{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-left:20px;
    margin-top: 10px;
}
.headerRegistration ul li:nth-child(1) a{
    margin-right: 10px;
}
.headerRegistration ul li:nth-child(2) a{
    margin-left: 10px;
}
.headerRegistration ul li a{
    text-decoration: none;
    color:#fff;
    font-size: 18px;
}
.headerRegistration ul li{
    list-style: none;
}
.headerRegistration ul{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    color:#fff;
}
.headerRegistration h3{
    font-size: 18px;
    color:#e4a354;
    text-transform: uppercase;
    font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
} 
.headerRegistrationWrap i{
    color:#e4a354;
    font-size: 30px;
    margin-right: 10px;
}
.headerRegistrationWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
}
@keyframes pulse{
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        transform: scale(0.8);
        opacity: 0;        
    }
}
#whatsAppFixed a:hover{
    transform: rotate(30deg);
}
#whatsAppFixed a{
    transition: ease 1s;
    display:block;
    position: absolute;
    z-index: 1;
    top:3px;
    left:-1px;
}
#whatsAppFixed::after {
    animation-delay: .5s;
}
#whatsAppFixed::before, #whatsAppFixed::after {
    content: '';
    position: absolute;
    border: 10px solid #69d74d;
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    border-radius: 100px;
    animation: pulse 1.8s linear infinite;
    z-index: 0;
}
#whatsAppFixed img {
    width: 70px;
    height: 70px;
}
#whatsAppFixed a {
    transition: ease 1s;
    display: block;
    position: absolute;
    z-index: 1;
    top: 3px;
    left: -1px;
}
#whatsAppFixed::before, #whatsAppFixed::after {
    content: '';
    position: absolute;
    border: 10px solid #69d74d;
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    border-radius: 100px;
    animation: pulse 1.8s linear infinite;
    z-index: 0;
}
#whatsAppFixed {
    position: fixed;
    bottom: 150px;
    right:1.5%;
    width:70px;
    height:70px;
    z-index: 3;
}
.headerSocial i:hover{
    color: #fff;
}
.headerSocial i{
    color:#a8a8a8;
    font-size: 25px;
    margin:0 5px;
    transition: ease .5s;
}
.headerSocial{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
}

.headerNavigationWrap{
    position: relative;
    z-index: 101;
}
.headerSocialWrap p{
    color:#fff;
    padding:5px 0;
}
.headerSocialWrap{
    margin-left: 30px;
    text-align: center;
    padding-top: 40px;
}
.headerForm input:focus{
    border:1px solid #ffc97b;
}
.headerForm i{
    color:#ffc97b;
    margin-right: 10px;
}
.headerForm button:hover{
    opacity: 0.8;
    padding-right: 30px;
}
.headerForm button{
    position: absolute;
    right:7px;
    top:4px;
    z-index: 2;
    border-radius: 50px;
    padding:6px 20px;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'CirceBold', Tahoma, Verdana, sans-serif;
    background-color: #333333;
    color: #fff;
    border:1px solid transparent;
    transition: ease .5s;
}
.headerForm input{
    width:520px;
    padding:20px 125px 20px 30px;
    position: absolute;
    font-size: 16px;
    left:0;
    right:0;
    bottom:0;
    top:0;
    border-radius: 50px;
    border:1px solid #000;
    outline: none;
    transition: ease .5s;
}
.headerForm{
    position: relative;
    margin-top:10px;
}
.headerTitleSearch p{
    color:#fff;
    font-size: 18px;
    font-family: 'CirceLight', Tahoma, Verdana, sans-serif;
    text-align: right;
}
.headerTitleSearch h2{
    font-size: 36px;
    color:#ffc97b;
    font-family: 'AkrobatExtraLight', Tahoma, Verdana, sans-serif;
}
.headerTitleSearch{
    padding-top: 20px;
    position: relative;
}
.headerCenterWrapper{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.headerCenterLogoA{
    transition:all ease .5s;
    text-decoration: none;
}
.headerCenterLogoA:hover {
    transform: scale(0.9);
}
.headerCenterLogo{
    text-align: center;
    display: inline-block;
}
.headerCenterLogo p{
    color:#ffc97b;
    font-family: 'AkrobatLight', Tahoma, Verdana, sans-serif;
}
.headerCenterContent{
    padding:10px 0;    
    margin-bottom:20px;
    border-bottom: 1px solid #3f3f3f;
    position: relative;
}
.headerCenterContent::after{
    content:'';
    bottom:-5px;
    left:0;
    right:0;
    width: 100%;
    height:1px;
    background-color:#3f3f3f;
    position:absolute;
    z-index:2;
}
.headerTopPhone span{
    font-size: 16px;
}
.headerTopPhone{
    font-size: 22px;
    font-family: 'CirceBold', Tahoma, Verdana, sans-serif;
}
.headerTopPhone i{
    color:#e4a354;
    margin-right: 5px;
    font-size:18px;
}
.headerTopAddress i{
    color:#e4a354;
    margin-right: 5px;
    font-size:18px;
}
.headerTopPhonesAddress a:hover{
    color:#e4a354;
}
.headerTopPhonesAddress a{
    text-decoration: none;
    color:#fff;
    display: block;
    padding:5px 5px 5px 10px;
    transition: ease 1s;
}
.headerTopPhonesAddress{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
}
.headerTopNav ul li a:hover{
    color:#e4a354;
}
.headerTopNav ul li a::before{
    content: '';
    position: absolute;
    top:12px;
    left:0;
    border: 4px solid transparent; 
    border-left: 4px solid #e19e07;
}
.headerTopNav ul li:first-child a{
    margin-left:0;
}
.headerTopNav ul li a{
    display:block;
    color:#fff;
    text-decoration: none;    
    padding:5px 5px 5px 10px;
    transition: ease 1s;
    position: relative;
    margin-left:20px;
}
.headerTopNav ul li{
    list-style: none;
}
.headerTopNav ul{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: center;
}
.headerTopNavWrap::after{
    content:'';
    bottom:-5px;
    left:0;
    right:0;
    width: 100%;
    height:1px;
    background-color:#3f3f3f;
    position:absolute;
    z-index:2;
}
.headerTopNavWrapper{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
p, h1, h2, h3, h4, ul{
    padding:0;
    margin: 0;
    font-weight: normal;
}
.headerTopNavWrap{
    border-bottom: 1px solid #3f3f3f;
    position: relative;
}
.headerTop{
    background-color: #000;
    border-bottom: 2px solid #a27d48;
    min-height: 240px;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(254,144,41);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: rgb(254,144,41);
    border-radius: 10px;
}
*::selection {
    background: rgb(254,144,41);
    color:#fff;
}
/* Fonts */
@font-face {
    font-family: 'AkrobatBold';
    src: url('../fonts/AkrobatBold.otf');
}
@font-face {
    font-family: 'AkrobatLight';
    src: url('../fonts/AkrobatLight.otf');
}
@font-face {
    font-family: 'AkrobatExtraLight';
    src: url('../fonts/AkrobatExtraLight.otf');
}
@font-face {
    font-family: 'AkrobatRegular';
    src: url('../fonts/AkrobatRegular.otf');
}
@font-face {
    font-family: 'CirceBold';
    src: url('../fonts/CirceBold.ttf');
}
@font-face {
    font-family: 'CirceExtraBold';
    src: url('../fonts/CirceExtraBold.ttf');
}
@font-face {
    font-family: 'CirceLight';
    src: url('../fonts/CirceLight.ttf');
}
@font-face {
    font-family: 'CirceRegular';
    src: url('../fonts/CirceRegular.ttf');
}
.postSkWrap{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.postPrice{
    width:220px;
    margin-top: 20px;
    background-color: rgb(151, 151, 151);
    height:100px;
}

.postInfoSk{
    width:220px;
    margin-top: 20px;
    background-color: rgb(151, 151, 151);
    height:30px;
}

.postImgSk{
    background-color: rgb(151, 151, 151);
    width: 220px;
    height: 250px;
}

.postSk{
    width: 220px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
    height:420px;
    animation: skeleton .5s ease infinite alternate;
}

@keyframes skeleton {
    to{
        opacity: 0.5;
    }    
}


@media only screen and (min-device-width : 320px) and (max-device-width : 768px)  { 
    .quickViewProduct{
        display: none !important;
    }
    #whatsAppFixed{
        display: none;
    }
    .homeAboutCompany {
        padding: 10px;
        display: block;
    }
    .headerNavigation {
        display:none;
        margin-left: 0;
        border-radius: 0;
    }
    .homeBanners img{
        width:100%;
    }
    .footerSubscribeWrap {
        margin-left: 0;
        border-radius: 0;
        display: block;
        padding-bottom: 0;
        margin-bottom: 0;
        z-index: 2;
        position: static;
        border-radius: 10px;
        margin-bottom: 20px;
    }
    .footerSubscribe input {
        border-radius: 50px;
        border: 1px solid transparent;
        outline: none;
        margin: 20px 0;
        padding: 10px 20px;
        position: static;
    }
    .footerSubscribe button {
        position: static;
        right: 6px;
        top: 6px;
        border-radius: 50px;
        background-color: #333333;
        border: 0;
        color: #fff;
        font-size: 12px;
        font-family: 'CirceBold', Tahoma, Verdana, sans-serif;
        padding: 12px 25px 12px 25px;
        transition: ease .5s;
    }
    .footerSubscribeWrap img {
        display:none;
    }
    .footerSubscribeWrap p {
        display:none;
    }
    .homeAboutCompanyText {
        width: 100%;
        margin-top:20px;
        text-align: left;
    }
    .footerSubscribe {
        position: static;
        display: block;
        min-width: 100%;
        text-align: center;
    }
    .headerTop {
        min-height: 100%;
    }
    .homeAboutCompany{
        text-align: center;
    }
    .footerLogoWrap img{
        display: inline-block;
        width:200px;
    }
    .footerLogoWrap {
        text-align: center;
        padding: 0px;
    }
    .homeTabs{
        text-align: center;
        margin-top: 30px;
    }
    .homeSlider {
        width: 100%;
    }
    .slick-slide img {
        width: 100%;
        height:100%;
    }
    .homeTabsBtn {
        font-size: 20px;
        padding: 10px 20px;
    }
    .homeBanners a:nth-child(1) {
        margin-bottom: 20px;
    }
    .homeBanners a:nth-child(2) {
        margin-bottom: 20px;
    }
    .homeBestItem img {
        max-width: 40px;
    }
    .homeBestItem h3 {
        font-size: 13px;
    }
    .homeBestItemText p{
        font-size: 12px;
    }
    .headerCenterContent::after {
        display:none;
    }
    .headerTopPhonesAddress{
        text-align: center;
        display: block;
    }
    .headerTitleSearch > p{
        display: none;
    }
    .headerTitleSearch h2 {
        font-size: 25px;
        color: #ffc97b;
        font-family: 'AkrobatExtraLight', Tahoma, Verdana, sans-serif;
    }
    .headerSocialWrap p, .headerSocialWrap .headerSocial{
        display: none;
    }
    .categoryNavigatinoWrap {
        display: block;
        text-align: center;
    }
    .categorySearch {
        width:100%;
        margin-bottom: 20px;
    }
    .headerSocialWrapWhats{
        position: fixed;
        bottom:10px;
        right:10px;
        z-index: 120;
    }
    .headerTop{
        padding-top:60px;
    }
    .homeCategoryItem {
        width: 98% !important;
    }
    .headerForm input{
        width:360px;
        padding:20px 125px 20px 30px;
        position: absolute;
        font-size: 16px;
        left:0;
        right:0;
        bottom:0;
        top:0;
        border-radius: 50px;
        border:1px solid #000;
        outline: none;
        transition: ease .5s;
    }
    .headerCenterWrapper {
        display: block;
    }
    .productImage{
        min-width: 100%;
        min-height:auto;
    }
    .footerSubscribeWrap::before {
        display: none;
    }
    .homeProductItemPrice span{
        font-size: 16px;
        margin-left:10px;
    }
    .homeProductItemPrice {
        font-size: 20px;
    }
    .homeBestItem {
        margin: 5px 0;
        padding: 0 10px;
        display: flex;
        display: -webkit-flex;
        flex-flow: row wrap;
        align-items: center;
        border-right: 0;
    }
    .homeTitle {
        font-family: 'AkrobatBold', Tahoma, Verdana, sans-serif;
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
    }
    .footerSocialWrap {
        background-color: rgb(254,144,41);
        height: 100%;
        width: 100%;
        padding: 0;
        border-radius: 10px;
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-around;
        margin-top:20px;
    }
    .footerSocialWrap a {
        display: block;
        color: #fff;
        margin: 10px;
        font-size: 20px;
        transition: ease .5s;
    }
    .footerBottomContentWrap{
        text-align: center;
        display: block;
    }
    .footerCategory {
        margin: 0;
        text-align: left;
        margin-top:30px;
        margin-bottom: 30px;
        max-width: 180px;
    }
    .footer {
        padding-bottom: 100px;
    }
    footer {
        margin-top:0;
    }
    .footerContent{
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: flex-start;    
        width:100%;
    }
    .slick-slider .homeProductItem{
        background-color: #fff;
        padding:5px;
        border:3px solid #fbebd5;
        border-radius: 10px;
        width: 98% !important;
        margin:0 5px 10px 5px;
        transition: ease .5s;
    }
    
    .categoryBrandsWrap {
        width: 100%;
        margin-right: 0;
    }
    #scroller img{
        width:30px;
    }
    .headerCenterLogo p {
        display: none;
    }
    #menuBtnWrap {
        border: 0;
        height: 50px;
        width: 50px;
        padding: 10px;
        position: fixed;
        z-index: 1200;
        right: -62px;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    .headerCartBtn a i {
        color: #e19e07;
        font-size: 30px;
    }
    .headerCart p {
        background-color: #fff;
        border-radius: 50px;
        display: inline-block;
        padding: 5px 10px;
        font-size: 14px;
        position: absolute;
        color: #333333;
        right: -5px;
        font-family: 'CirceExtraBold', Tahoma, Verdana, sans-serif;
        bottom: -5px;
        line-height: 1;
    }
    .mobileHeader {
        background-color: #000;
        border-bottom: 3px solid rgb(254,144,41);
        display: block;
        height: 60px;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index:90;
    }
    .headerRegistrationWrap{
        position: fixed;
        right:0;
        top:0;
    }
    .headerCartBtn {
        bottom: 15px;
        position: fixed;
        left:20px;
        z-index: 10;
    }
    .checkoutItemBtn button {
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .checkoutCart{
        margin-bottom: 20px;
    }
    .checkOutForm{
        display: inline-block;
    }
    .checkoutContent {
        text-align: center;
        display: block;
    }
    .headerBtn {
        display:inline-block;
        margin-top: 10px;
        padding: 5px 8px 5px 8px;
        background: url(/public/uploads/design/headerBtn.jpg) center center no-repeat;
        border-radius: 50px;
        font-size: 12px;
    }
    .headerForm{
        display: block;
    }
    .headerCartFavourite{
        text-align: center;
    }
    .cartSummaryBtns a>button {
        display: inline-block;
        font-size: 18px;
        margin-top: 20px;
    }
    .cartSummaryBtns>button {
        display: inline-block;
    }
    .cartSummaryBtns {
        display: block;
        text-align: center;
    }
    .headerTitleSearch {
        padding-top: 0;
        position: relative;
        z-index: 0 !important;
    }
    .cartSummaryWrap {
        text-align: center;
    }
    .headerCenterLogo img{
        height:50px;
    }
    .headerCenterLogo {
        left: 85px;
        top:3px;
        position: fixed;
        z-index: 100;
    }
    .headerRegistration ul li a {
        font-size: 14px;
    }
    .headerRegistrationWrap{
        right: 20px;
        top:10px;
        position: fixed;
        z-index: 100;
    }
    .headerFavBtn a i {
        color: #e19e07;
        font-size: 30px;
    }
    .headerFavBtn{
        bottom: 15px;
        position: fixed;
        left:70px;
        z-index: 10;
    }
    .mobileFooter {
        background-color: #000000;
        border-top: 3px solid rgb(254,144,41);
        bottom: 0;
        display: block;
        height: 60px;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 9;
    }
    .headerRegistration h3 {
        font-size: 15px;
    }
    .categoryItemsWrap .homeProductItem{
        width:47% !important;
    }
    .categoryItemsBtnWrap {
        width: 100%;
    }
    .categoryItemsWrap .homeProductItem img {
        width: 100%;
        height: 100%;
    }
    .categoryItemsWrap .homeProductItem img {
        min-height: 160px;
    }
    
    .related .homeProductItem{
        width:47% !important;
    }
    .related {
        width: 100%;
    }
    .related .homeProductItem img {
        width: 100%;
        height: 100%;
    }
    .related .homeProductItem img {
        min-height: 160px;
    }
    .slick-list .homeProductItem img{
        min-width: 160px;
        min-height: 160px;
    }
    .searchContentWrap .homeProductItem{
        width:47% !important;
    }
    .searchContentWrap {
        width: 100%;
    }
    .list-group-item a h3 {
        font-size: 16px;
        text-align: center;
    }
    .list-group-item img {
        max-height: 50px;
        width: auto;
    }
    .searchContentWrap .homeProductItem img {
        width: 100%;
        height: 100%;
    }
    .searchContentWrap .homeProductItem img {
        min-height: 160px;
    }
    .commonPagesContentWrap .productDetailsSPrice {
        font-size: 30px;
        text-align: center;
    }
    .productDetailsSPrice {
        font-size: 20px;
    }
    .productUnitWrap {
        justify-content: center;
    }
    .productDetailsSPrice span {
        font-size: 16px;
    }
    .productDetailsSPrice{
        margin-top: 10px;
    }
    header {
        margin-bottom: 10px;
    }
    .oneCategoryWrap h1 {
        margin-top: 0;
        font-size:28px;
    }
    .bread {
        font-size:12px;
    }
    .categorySearch input {
        border: 1px solid rgb(227, 227, 227);
    }
    .categorySearch input:focus + .catSeachLabel .catSeachName, .categorySearch input:valid + .catSeachLabel .catSeachName {
        padding-left: 0;
    }
    .categoriesContentWrap {
        margin: 10px 0;
    }
    .mobileShow {
        display: block;
        height: 100vh !important;
        left: 5px;
        position: fixed !important;
        top: 2px;
        bottom:0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        transition: .5s;
        width: 300px;
        z-index: 102;
    }
    .menuBtn {
        background-color: #fff;
        display: block;
        height: 3px;
        position: relative;
        width: 28px;
    }
    .menuBtn::before {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        left: 0;
        top: -10px;
        background-color: #fff;
        position: absolute;
        transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
    }
    .menuBtn::after {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        left: 0;
        bottom: -10px;
        background-color: #fff;
        position: absolute;
        transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
    }
    .menuIconActive{
        background-color:transparent;
    }
    .menuActive {
        transform: translateX(-2%);
        -webkit-box-shadow: 10px -4px 5px -6px rgb(0 0 0 / 20%);
        -moz-box-shadow: 10px -4px 5px -6px rgba(0,0,0,0.2);
        box-shadow: 10px -4px 5px -6px rgb(0 0 0 / 20%);
        background-color: transparent;
        border-right:3px solid #ff9918;
    }
    .mainContentActive {
        transform: translateX(79%);
    }
    .mainContentWrap {
        transition: .5s ease;
    }
    
    .menuIconActive::after{
        transform:rotate(-45deg);
        bottom: 0;
        transition:top 0.2s linear, transform 0.2s ease-in 0.2s;
    }

    .menuIconActive::before{
        transform:rotate(45deg);
        top: 0;
        transition:top 0.2s linear, transform 0.2s ease-in 0.2s;
    }
    .headerTopNavWrap {
        display:none;
    }
    .formMainAuth img{
        display: none;
    }
    .mobileNavItem{
        background-color: #fff;
        display: block;
        margin:5px;
        border-radius: 10px;
        border: 1px solid #e3e3e3;
        padding:10px;
        text-decoration: none;
        color:#333333;
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-flow: row wrap;
        align-items: center;
    }
    .mobileNavItem img{
        width:50px;
        margin-right: 10px;
    }
    .mobileNavItem p{
        max-width: 200px;
        line-height: 1;
    }
    .mobileTopInfo a{
        display: block;
        margin-bottom: 10px;
        color:#333333;
        text-decoration: none;
    }
    .mobileTopInfo{
        text-align: center;
    }
    .mobileTopInfo a:first-child{
        margin-top: 10px;
    }
    .mobileTopInfo a i{
        color:#ff990a;
        margin-right: 10px;
    }
    .commonPagesContentWrap {
        min-height: 100%;
    }
    .productDetails {
        padding-top: 10px;
        display: block;
    }
    .cartImageMain {
        max-width: 100%;
        margin-bottom: 30px;
    }.productDetailsContent {
        width: 100%;
        padding: 20px;
    }
    .cartProdQty {
        justify-content: center !important;
    }
    .productDetailsRight .productQty {
        justify-content: center;
    }
    .productDetailsRight {
        min-width: 100%;
        padding: 10px 0;
        text-align: left;
    }
    .productUnitWrap .active {
        background-color: #686868;
        border: 0;
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
    }
    .productUnitKor {
        font-size: 12px;
    }
    .productUnitUp {
        font-size: 12px;
    }
    .productCartBtns {
        padding: 10px;
        align-items: center;
    }
    .productCartBtn {
        font-size:20px;
        margin-right: 10px;
        padding: 5px 20px;
    }
    .productFavBtn span{
        display: inline-block;
    }
    .productFavBtn{
        text-align: center;
    }
    .productOneClickBtn {
        font-size: 14px;
    }
    .productDetailsContentWrap {
        display: block;
    }
    .catProductBtn{
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
    }
    .Toastify__toast-body {
        font-size: 12px !important;
    }
    .cartBtnProduct {
        margin: 0 0 0 10px;
    }
    .productFront h2 {
        font-size: 14px;
    }
    .cartUnitType {
        font-size: 11px;
    }
    .cartBtnProduct i {
        font-size: 16px;
    }
    .favBtnProduct {
        font-size: 16px;
    }
    .productDetailsContent h1 {
        font-size: 28px;
        text-align: center;
        line-height: 1;
    }
    .categoryBrandContent {
        margin-bottom: 30px;
    }
    .homeAboutCompanyText {
        font-size: 14px;
    }
    .cart-empty i {
        font-size: 100px;
    }
    .wishlistWrap i {
        font-size: 100px;
    }
    #mobileHeaderPhone a{
        display:block;
        position: fixed;
        bottom:-5px;
        right:70px;
        z-index: 120;
        color:#d09239;
        font-size: 40px;
    }
}